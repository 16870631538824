import DownArrow from 'components/DownArrow'
import Drip from 'components/Drip'

function Hero({ content, downArrowSelector = null, downArrowLabel = null }) {
  return (
    <>
      <div className='border-glow border-t-4 min-h-screen bg-gray-950 text-gray-100 px-8 md:px-24 xl:px-48 xxl:px-64'>
        {content}

        {downArrowSelector && downArrowLabel && (
          <DownArrow selector={downArrowSelector} label={downArrowLabel} />
        )}
      </div>
      <Drip />
    </>
  )
}

export default Hero
