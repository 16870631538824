import ButtonLink from 'components/ButtonLink'
import UndrawVoid from 'components/images/UndrawVoid'

function NotFoundHero() {
  return (
    <>
      <div className='flex flex-col gap-4 pt-32'>
        <h1 className='text-3xl md:text-5xl font-bold text-center'>
          Sorry, <span className='text-emerald-400'>Nothing's Here!</span>
        </h1>
        <div className='grid grid-cols-1 lg:grid-cols-2 justify-items-center gap-16 px-6 md:px-24 mt-16'>
          <div
            className='flex items-center justify-center'
            data-aos='fade-down'
            data-aos-delay='150'
            data-aos-duration='600'
            data-aos-once='true'
          >
            <UndrawVoid classes='w-64' />
          </div>
          <div className='flex flex-col items-center justify-center text-center text-base gap-2 max-w-xl leading-7 md:leading-8'>
            <p className='text-indigo-100 font-bold text-2xl md:text-3xl text-balance w-64 sm:w-80 md:w-96 lg:w-96 xl:w-96'>
              You must be lost<span className='text-emerald-400'>...</span>
            </p>
            <p className='text-gray-400 text-sm md:text-md xl:text-base leading-6 md:leading-8'>
              Don't worry! You'll find your way back home!
            </p>
            <div className='pb-12 pt-6'>
              <ButtonLink link='/' label='Click here to return home.' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundHero
