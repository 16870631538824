import HeroText from 'components/HeroText'
import UndrawFeelingProud from 'components/images/UndrawFeelingProud'

function ProjectsHero() {
  return (
    <>
      <div className='flex flex-col pt-32'>
        <h1 className='text-3xl md:text-5xl font-bold text-center'>
          My <span className='text-emerald-400'>Projects</span>
        </h1>
        <div className='grid grid-cols-1 lg:grid-cols-2 justify-items-center gap-16 px-6 md:px-24 mt-16'>
          <div
            className='flex items-center justify-center'
            data-aos='fade-right'
            data-aos-delay='150'
            data-aos-duration='600'
            data-aos-once='true'
          >
            <UndrawFeelingProud classes='w-64' />
          </div>
          <div className='flex flex-col items-center justify-center text-center text-base gap-4 max-w-xl'>
            <p className='text-indigo-100 font-bold text-2xl md:text-3xl text-balance w-64 sm:w-80 md:w-96'>
              Check out some of the things I've built!
            </p>
            <hr className='border border-emerald-400 w-full' />
            <HeroText
              text='Each project contains a link to the source code on GitHub and a
              link to the live website or application. All of my projects are
              open source and are primarily for my own learning purposes.'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectsHero
