import Hero from 'components/Hero'
import HomeHero from 'components/HomeHero'

import UndrawProgramming from 'components/images/UndrawProgramming'
import UndrawMusician from 'components/images/UndrawMusician'
import ContactForm from 'components/ContactForm'

function Home() {
  return (
    <>
      <Hero
        content={<HomeHero />}
        downArrowSelector='#about'
        downArrowLabel='Learn more about me below!'
      />
      <div
        id='about'
        className='grid grid-cols-1 lg:grid-cols-2 gap-16 px-4 md:px-24 md:mt-6 xxl:mt-12 md:mb-24 xl:px-48 xxl:px-64'
      >
        <div
          className='order-1 flex items-center justify-center'
          data-aos='fade-up'
          data-aos-delay='150'
          data-aos-duration='600'
          data-aos-once='true'
        >
          <UndrawProgramming classes='w-64 md:w-96' />
        </div>
        <div className='order-2 flex flex-col gap-6 items-center justify-center pb-16 px-8'>
          <div className='text-center lg:text-left w-full'>
            <h3 className='text-4xl font-bold'>Who Am I?</h3>
          </div>
          <p className='leading-8 tracking-wide text-gray-200 text-sm md:text-base xl:text-xl'>
            I am a full-stack web developer, primarily using the{' '}
            <strong className='text-orange-400'>Laravel</strong> framework in{' '}
            <strong className='text-violet-400'>PHP</strong>. I am also
            proficient in{' '}
            <strong className='text-yellow-400'>JavaScript</strong> with
            experience using <strong className='text-emerald-300'>Vue</strong>{' '}
            and <strong className='text-blue-400'>React</strong>. I have been
            building websites and web applications for over 15 years, and I{' '}
            <span role='img' aria-label='heart emoji'>
              ❤️
            </span>{' '}
            what I do!
          </p>
          <p className='leading-8 tracking-wide text-gray-200 text-sm md:text-base xl:text-xl'>
            I am based in Memphis, TN with an{' '}
            <strong className='text-rose-400'>Associate's Degree</strong> in{' '}
            <strong className='text-rose-400'>Computer Programming</strong> and
            a <strong className='text-cyan-400'>Bachelor's Degree</strong> in{' '}
            <strong className='text-cyan-400'>
              Management Information Systems
            </strong>
            .
          </p>
        </div>
        <div className='order-4 md:order-3 flex flex-col gap-6 items-center justify-center pb-16 px-8'>
          <div className='text-center lg:text-left w-full'>
            <h3 className='text-3xl font-bold'>A bit more about me...</h3>
          </div>
          <p className='leading-8 tracking-wide text-gray-200 text-sm md:text-base xl:text-xl'>
            In addition to coding, I am also a part-time{' '}
            <strong className='text-red-400'>musician</strong> and{' '}
            <strong className='text-red-400'>sound engineer</strong>. I play
            guitar and bass guitar, and you will often find me tinkering in
            music production.
          </p>
          <p className='leading-8 tracking-wide text-gray-200 text-sm md:text-base xl:text-xl'>
            My favorite job of all, however, is being{' '}
            <strong className='text-pink-400'>husband</strong> to a loving and
            supportive wife, and father to my two amazing kids.
          </p>
        </div>
        <div
          className='order-3 md:order-4 flex items-center justify-center'
          data-aos='fade-up'
          data-aos-delay='150'
          data-aos-duration='600'
          data-aos-once='true'
        >
          <UndrawMusician classes='w-48 md:w-80' />
        </div>
      </div>
      <div
        className='px-4 md:px-24 xl:px-48 xxl:px-64 mb-16'
        data-aos='zoom-in-up'
        data-aos-duration='1000'
        data-aos-once='true'
      >
        <ContactForm />
      </div>
    </>
  )
}

export default Home
