import FooterDrip from 'components/FooterDrip'
import FooterLink from 'components/FooterLink'
import Socials from 'components/Socials'
import UndrawCodeThinking from 'components/images/UndrawCodeThinking'

function Footer({ route }) {
  return (
    <>
      <FooterDrip />
      <div className='bg-gray-950 text-gray-100 grid grid-cols-1 lg:grid-cols-3 gap-8 px-6 md:px-24 py-16'>
        <div className='flex items-center'>
          <UndrawCodeThinking classes='w-56' />
        </div>
        <div className='flex flex-col gap-6'>
          <div className='w-full flex flex-col gap-2'>
            <h4 className='text-emerald-400 text-2xl font-semibold'>
              Site Navigation
            </h4>
            <hr className='border border-emerald-400 w-full' />
          </div>
          <ul className='list-none flex flex-col gap-2 text-gray-400'>
            <li>
              <FooterLink link='/' label='Home & About Me' route={route} />
            </li>
            <li>
              <FooterLink
                link='/skills'
                label='Skills & Experience'
                route={route}
              />
            </li>
            <li>
              <FooterLink link='/projects' label='My Projects' route={route} />
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-6'>
          <div className='w-full flex flex-col gap-2'>
            <h4 className='text-emerald-400 text-2xl font-semibold'>
              Social Links
            </h4>
            <hr className='border border-emerald-400 w-full' />
          </div>
          <div className='flex items-start'>
            <Socials />
          </div>
          <div className='text-sm text-gray-400'>
            &copy; {new Date().getFullYear()} Chase Terry. All rights reserved.
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
