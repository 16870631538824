import Hero from 'components/Hero'
import SkillsHero from 'components/SkillsHero'
import UndrawCodeInspection from 'components/images/UndrawCodeInspection'
import UndrawDesigner from 'components/images/UndrawDesigner'

function Skills() {
  return (
    <>
      <Hero
        content={<SkillsHero />}
        downArrowSelector='#about'
        downArrowLabel='Learn more about me below!'
      />
      <div
        id='details'
        className='grid grid-cols-1 lg:grid-cols-2 gap-16 px-4 md:px-24 md:mb-32 md:mt-12 xl:px-48 xxl:px-64'
      >
        <div className='order-2 lg:order-1 flex flex-col gap-6 items-center justify-center'>
          <div className='text-center lg:text-left w-full'>
            <h3 className='text-4xl font-bold'>What Do I Do?</h3>
          </div>
          <ul className='list-disc list-outside flex flex-col gap-2 px-8 leading-8 tracking-wide text-gray-200 text-sm md:text-base xl:text-xl'>
            <li>
              Design and build websites and applications using various
              technologies, including <strong>HTML</strong>,{' '}
              <strong>CSS</strong>, <strong>JavaScript</strong>,{' '}
              <strong>PHP</strong> and popular libraries and frameworks like{' '}
              <strong>Tailwind</strong>, <strong>React</strong>,{' '}
              <strong>Vue</strong>, and <strong>Laravel</strong>
            </li>
            <li>
              Aim for maintainable and reliable code by using industry
              best-practices and <strong>Test Driven Development</strong>
            </li>
            <li>
              Strive for optimal user experience by making my websites and
              applications <strong>responsive</strong> and{' '}
              <strong>accessible</strong> and optimized for SEO.
            </li>
          </ul>
        </div>
        <div
          className='order-3 lg:order-2 flex items-center justify-center'
          data-aos='fade-up'
          data-aos-delay='150'
          data-aos-duration='600'
          data-aos-once='true'
        >
          <UndrawCodeInspection classes='w-64 md:w-96' />
        </div>
        <div
          className='order-1 lg:order-3 flex items-center justify-center'
          data-aos='fade-up'
          data-aos-delay='150'
          data-aos-duration='600'
          data-aos-once='true'
        >
          <UndrawDesigner classes='w-64 md:w-80' />
        </div>
        <div className='order-4'>
          <ul className='list-disc list-outside flex flex-col gap-2 px-8 leading-8 tracking-wide text-gray-200 text-sm md:text-base xl:text-xl'>
            <li>
              Use relational databases like <strong>MySQL</strong> and NoSQL
              databases like <strong>MongoDB</strong> to store and retrieve data
            </li>
            <li>
              Configure web servers like <strong>Apache</strong> and{' '}
              <strong>Nginx</strong>
            </li>
            <li>
              Manage my code through <strong>Git</strong> version control on{' '}
              <strong>GitHub</strong> and using Github Actions for CI/CD
            </li>
            <li>
              Deploy and manage my websites and applications on{' '}
              <strong>Linux</strong> servers in various cloud platforms like{' '}
              <strong>DigitalOcean</strong> and <strong>AWS</strong>
            </li>
            <li>
              Build fun hobby projects to learn and grow my skills -- many of
              which you will find here on my website!
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Skills
