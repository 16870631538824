import HeroText from 'components/HeroText'
import SkillCircle from 'components/SkillCircle'

import skills from 'data/skills'

function SkillsHero() {
  return (
    <>
      <div className='flex flex-col gap-4 pt-32'>
        <h1 className='text-3xl md:text-5xl font-bold text-center'>
          Skills & <span className='text-emerald-400'>Experience</span>
        </h1>
        <div className='w-full flex items-center justify-center'>
          <div className='max-w-lg'>
            <HeroText
              text='Here are a few of the technologies I use to create modern,
            robust, and user-friendly websites and applications.'
            />
          </div>
        </div>
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 justify-items-center gap-x-6 gap-y-12 py-12'>
          {skills.map((skill, index) => (
            <div
              key={index}
              data-aos='fade-down'
              data-aos-delay={index * 100}
              data-aos-duration='600'
              data-aos-once='true'
            >
              <SkillCircle skill={skill} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SkillsHero
