import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isFormError, setIsFormError] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleRecaptcha = token => {
    setRecaptchaToken(token)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const response = await fetch(
      'https://4p6ley7w22.execute-api.us-east-2.amazonaws.com/send-email',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...formData, recaptchaToken })
      }
    )

    if (response.ok) {
      setFormData({ name: '', email: '', message: '' })
      setRecaptchaToken(null)
      setIsFormSubmitted(true)
    } else {
      console.error('Failed to send email.')
      setIsFormError(true)
    }
  }

  return (
    <div className='w-full mx-auto'>
      <div className='text-indigo-200 p-6 sm:p-8 rounded-xl bg-gray-930 shadow-md transition duration-500 ease-in-out'>
        {isFormError ? (
          <div className='text-red-300'>
            <h2 className='text-2xl sm:text-3xl font-semibold mb-2 text-center'>
              Something Went Wrong!
            </h2>
            <p className='text-xs sm:text-sm md:text-base mb-4 text-center'>
              Sorry, your message could not be sent at this time.
              <br />
              Please try again later.
            </p>
          </div>
        ) : (
          <div>
            {isFormSubmitted ? (
              <div>
                <h2 className='text-2xl sm:text-3xl font-semibold mb-2 text-center'>
                  Message Sent!
                </h2>
                <p className='text-sm sm:text-base mb-4 text-center'>
                  Thank you for reaching out to me!
                  <br />
                  I'll get back to you as soon as possible.
                </p>
              </div>
            ) : (
              <div>
                <h2 className='text-2xl sm:text-3xl font-semibold mb-2 text-center'>
                  Contact Me!
                </h2>
                <p className='text-sm sm:text-base mb-4 text-center'>
                  Have a question or want to discuss a project?
                  <br />
                  Send me a message!
                </p>
                <form onSubmit={handleSubmit} className='space-y-6'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium'>
                      Your Name
                    </label>
                    <input
                      type='text'
                      name='name'
                      id='name'
                      placeholder='Tell me your name...'
                      value={formData.name}
                      onChange={handleChange}
                      className='bg-gray-920 mt-1 block w-full px-3 py-2 border border-gray-930 shadow-sm focus:outline-none focus:ring-indigo-700 focus:border-indigo-700 text-sm sm:text-base xl:text-lg'
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium'
                    >
                      Your Email
                    </label>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      placeholder='And your email...'
                      value={formData.email}
                      onChange={handleChange}
                      className='bg-gray-920 mt-1 block w-full px-3 py-2 border border-gray-930 rounded-md shadow-sm focus:outline-none focus:ring-indigo-700 focus:border-indigo-700 text-sm sm:text-base xl:text-lg'
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='message'
                      className='block text-sm font-medium'
                    >
                      Your Message
                    </label>
                    <textarea
                      name='message'
                      id='message'
                      placeholder='And type your message here!'
                      value={formData.message}
                      onChange={handleChange}
                      className='bg-gray-920 mt-1 block w-full px-3 py-2 border border-gray-930 shadow-sm focus:outline-none focus:ring-indigo-700 focus:border-indigo-700 text-sm sm:text-base xl:text-lg'
                      rows='4'
                      required
                    />
                  </div>
                  <div className='mt-4'>
                    <ReCAPTCHA
                      sitekey='6Le3cCYqAAAAAH9MNKB0IpHcAo3rQKUPtozuknkx'
                      onChange={handleRecaptcha}
                    />
                  </div>
                  <div>
                    <button
                      type='submit'
                      className='w-full px-4 py-2 bg-indigo-900 text-indigo-200 font-semibold shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 xl:text-lg'
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactForm
