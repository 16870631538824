function ButtonLink({ link, label }) {
  return (
    <>
      <a
        href={link}
        className='text-sm md:text-md bg-emerald-400 text-gray-950 font-bold py-2 px-4 rounded-full hover:bg-emerald-500 transition duration-300 ease-in-out'
      >
        {label}
      </a>
    </>
  )
}

export default ButtonLink
