import Github from 'components/icons/Github'
import View from 'components/icons/View'
import ComingSoon from 'components/icons/ComingSoon'

function ProjectCard(props) {
  const { project, isReversed } = props
  const { name, description, image, link, github, tech } = project

  return (
    <>
      <div className='bg-gray-930 grid grid-cols-1 sm:grid-cols-2 gap-6 hover:bg-gray-920 hover:scale-105 transition duration-300 ease-in-out rounded-xl shadow-lg'>
        <div
          className={`${
            isReversed ? 'sm:order-last justify-end' : 'sm:order-first'
          }`}
        >
          <img
            src={image}
            alt={name}
            title={name}
            className={`w-full h-64 sm:h-80 xxl:h-96 object-fill rounded-tr-xl rounded-tl-xl rounded-bl-none rounded-br-none ${
              isReversed
                ? 'sm:rounded-tr-xl sm:rounded-br-xl sm:rounded-tl-none sm:rounded-bl-none'
                : 'sm:rounded-tl-xl sm:rounded-bl-xl sm:rounded-tr-none sm:rounded-br-none'
            }`}
          />
        </div>
        <div className='h-full flex flex-col justify-between gap-6 p-6'>
          <div className='flex flex-col gap-2 xl:gap-4'>
            <h3 className='font-bold text-xl xl:text-3xl text-gray-100'>
              {name}
            </h3>
            <p className='text-gray-300 text-sm xl:text-lg xxl:text-xl'>
              {description}
            </p>
            <div className='flex flex-wrap gap-2 mt-2'>
              {tech.map((tech, index) => (
                <span
                  key={index}
                  className='px-2 py-1 bg-indigo-700 text-gray-200 rounded-md text-sm shadow-sm hover:bg-indigo-600 transition duration-200 ease-in-out select-none'
                >
                  {tech}
                </span>
              ))}
            </div>
          </div>
          <div>
            <hr className='mb-4 border-indigo-300' />
            {link === '#' && github === '#' ? (
              <div className='w-full'>
                <span className='flex items-center justify-center gap-2 px-4 py-2 rounded-md bg-red-500 text-white transition duration-200 ease-in-out select-none'>
                  <ComingSoon classes='w-5 h-5' fill='white' />
                  Coming Soon
                </span>
              </div>
            ) : (
              <div className='grid grid-cols-2 gap-4'>
                <a
                  href={link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex items-center justify-center gap-2 px-4 py-2 rounded-md bg-emerald-500 text-white hover:bg-emerald-600 transition duration-200 ease-in-out'
                >
                  <View classes='w-5 h-5' fill='white' />
                  View
                </a>
                <a
                  href={github}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex items-center justify-center gap-2 px-4 py-2 rounded-md bg-gray-950 text-white hover:bg-gray-800 transition duration-200 ease-in-out'
                >
                  <Github classes='w-5 h-5 mr-2' fill='white' />
                  GitHub
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectCard
