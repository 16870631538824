function HeroText({ text }) {
  return (
    <>
      <p className='text-gray-400 text-center leading-6 md:leading-7 text-sm md:text-base xl:text-lg'>
        {text}
      </p>
    </>
  )
}

export default HeroText
