import ChevronDown from 'components/icons/ChevronDown'

function DownArrow({ selector, label }) {
  return (
    <>
      <div className='flex items-center justify-center text-md xl:text-xl mt-8 md:mt-24 xl:mt-40'>
        <button
          onClick={e => {
            e.preventDefault()
            document
              .querySelector(selector)
              .scrollIntoView({ behavior: 'smooth' })
          }}
        >
          <div className='flex flex-col gap-4 items-center justify-center'>
            <p>{label}</p>
            <ChevronDown fill='#ffffff' classes='w-8 h-8 animate-bounce' />
          </div>
        </button>
      </div>
    </>
  )
}

export default DownArrow
