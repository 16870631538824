import Close from 'components/icons/Close'
import Menu from 'components/icons/Menu'

function NavigationIcon(props) {
  const getMenuIcon = () => {
    return props.isMenuOpen ? (
      <Close fill='white' classes='h-6 w-6 group-hover:fill-current' />
    ) : (
      <Menu fill='white' classes='h-6 w-6 group-hover:fill-current' />
    )
  }

  return (
    <>
      <div className='fixed top-8 right-8 group z-50'>
        <div
          onClick={props.onClick}
          className='bg-gray-950 border-4 border-glow group-hover:border-emerald-400 group-hover:text-emerald-400 rounded-full h-12 w-12 flex items-center justify-center cursor-pointer'
        >
          {getMenuIcon()}
        </div>
      </div>
    </>
  )
}

export default NavigationIcon
