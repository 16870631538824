import Hero from 'components/Hero'
import ProjectsHero from 'components/ProjectsHero'
import ProjectCard from 'components/ProjectCard'

import projects from 'data/projects'

function Projects() {
  return (
    <>
      <Hero
        content={<ProjectsHero />}
        downArrowSelector='#projects'
        downArrowLabel='See my projects below!'
      />
      <div
        id='projects'
        className='flex flex-col gap-12 px-4 md:px-24 xl:px-48 xxl:px-64 pb-16'
      >
        {projects.map((project, index) => (
          <div
            key={index}
            data-aos='fade-up'
            data-aos-delay={index * 50}
            data-aos-duration='600'
            data-aos-once='true'
          >
            <ProjectCard project={project} isReversed={index % 2 !== 0} />
          </div>
        ))}
      </div>
    </>
  )
}

export default Projects
