import Hero from 'components/Hero'
import NotFoundHero from 'components/NotFoundHero'

function NotFound() {
  return (
    <>
      <Hero content={<NotFoundHero />} />
    </>
  )
}

export default NotFound
