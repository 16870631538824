import NavigationMenuLink from 'components/NavigationMenuLink'
import UndrawDeveloperActivity from 'components/images/UndrawDeveloperActivity'

function NavigationMenu({ isMenuOpen, route }) {
  return (
    <>
      <div
        className={`fixed bg-gray-940 text-gray-100 border-t-4 border-glow p-8 h-full w-full top-16 z-40 transition duration-300 ease-linear rounded-t-3xl ${
          isMenuOpen
            ? '-translate-y-0 opacity-100'
            : 'translate-y-full opacity-0'
        }`}
      >
        <div className='flex flex-col justify-between h-full'>
          <div className='flex flex-col gap-8'>
            <div className='flex items-center justify-center'>
              <div className='bg-gray-950 border-glow border-4 text-4xl h-20 w-20 flex items-center justify-center rounded-full select-none hover:scale-110 hover:shadow-lg hover:shadow-emerald-400 transition duration-300 ease-in-out cursor-pointer'>
                CT
              </div>
            </div>
            <nav className='w-full flex items-center flex-col gap-3 text-2xl text-center'>
              <NavigationMenuLink
                link='/'
                label='Home & About Me'
                route={route}
              />
              <NavigationMenuLink
                link='/skills'
                label='Skills & Experience'
                route={route}
              />
              <NavigationMenuLink
                link='/projects'
                label='My Projects'
                route={route}
              />
            </nav>
          </div>
          <div className='flex items-center justify-center pb-20'>
            <UndrawDeveloperActivity classes='w-32' />
          </div>
        </div>
      </div>
    </>
  )
}

export default NavigationMenu
