function NavigationMenuLink({ link, label, route }) {
  return (
    <>
      <a
        href={link}
        className={
          'w-full sm:w-80 px-4 py-2 rounded-lg border-2 border-gray-940 transition duration-200 ease-in-out hover:bg-gray-950 hover:text-emerald-400 hover:border-emerald-400 ' +
          (route === link ? 'text-emerald-400 italic' : '')
        }
      >
        {label}
      </a>
    </>
  )
}

export default NavigationMenuLink
