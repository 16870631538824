function SkillCircle(props) {
  const { skill } = props
  const { logo, name } = skill

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='w-24 h-24 rounded-full bg-gray-900 flex items-center justify-center border-4 border-gray-900 hover:shadow-lg hover:shadow-emerald-400 hover:scale-110 transition duration-200 ease-in-out'>
          <img src={logo} alt={name} title={name} className='w-2/3' />
        </div>
        <span className='text-center text-lg'>{name}</span>
      </div>
    </>
  )
}

export default SkillCircle
