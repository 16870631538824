import TypeWriterEffect from 'react-typewriter-effect'

import Chase from 'assets/images/chase.jpeg'
import HeroText from 'components/HeroText'
import Socials from 'components/Socials'

function HomeHero() {
  return (
    <>
      <div className='flex flex-col-reverse gap-16 lg:gap-6 lg:flex-row lg:items-center pb-16 pt-32 md:pt-48 xxl:pt-56'>
        <div className='flex flex-col items-center gap-6 w-full select-none'>
          <h1 className='text-indigo-200 text-center text-4xl font-bold md:text-6xl'>
            Hi, I'm Chase!
          </h1>
          <h2 className='flex flex-wrap items-center gap-1 font-bold text-2xl sm:flex-row md:text-3xl'>
            I am a
            <span className='text-emerald-400 underline ml-1'>
              <TypeWriterEffect
                multiTextLoop
                cursorColor='#ffffff'
                multiText={[
                  'PHP Developer',
                  'Laravel Developer',
                  'JavaScript Developer',
                  'React Developer',
                  'Full-Stack Developer'
                ]}
                multiTextDelay={1200}
                typeSpeed={125}
              />
            </span>
          </h2>
          <HeroText text='With over 15 years of experience in web development and a passion for creating beautiful, responsive websites and applications.' />
          <hr className='border border-emerald-400 w-full' />
          <Socials />
        </div>
        <div className='flex items-start justify-center w-full'>
          <img
            src={Chase}
            alt='Chase Terry'
            className='pulse-and-glow w-48 md:w-80 rounded-full border-4'
            data-aos='fade-down'
            data-aos-delay='200'
            data-aos-duration='900'
            data-aos-once='true'
          />
        </div>
      </div>
    </>
  )
}

export default HomeHero
