import Discord from 'components/icons/Discord'
import Email from 'components/icons/Email'
import Github from 'components/icons/Github'
import LinkedIn from 'components/icons/LinkedIn'

function Socials() {
  return (
    <>
      <div className='flex items-center justify-center gap-8'>
        <a
          href='https://linkedin.com/in/chase-terry'
          rel='noreferrer'
          target='_blank'
          aria-label='LinkedIn Profile'
          title='LinkedIn Profile'
        >
          <LinkedIn
            fill='white'
            classes='h-8 hover:fill-current hover:text-emerald-400 hover:scale-125 transition duration-200 ease-in-out'
          />
        </a>
        <a
          href='https://github.com/cdterry87'
          rel='noreferrer'
          target='_blank'
          aria-label='Github Profile'
          title='Github Profile'
        >
          <Github
            fill='white'
            classes='h-8 hover:fill-current hover:text-emerald-400 hover:scale-125 transition duration-200 ease-in-out'
          />
        </a>
        <a
          href='https://discordapp.com/users/353702942294933505'
          rel='noreferrer'
          target='_blank'
          aria-label='Add Me On Discord'
          title='Add Me On Discord'
        >
          <Discord
            fill='white'
            classes='h-8 hover:fill-current hover:text-emerald-400 hover:scale-125 transition duration-200 ease-in-out'
          />
        </a>
        <a
          href='mailto:chase.terry87@gmail.com'
          rel='noreferrer'
          target='_blank'
          aria-label='Send Me An Email'
          title='Send Me An Email'
        >
          <Email
            fill='white'
            classes='h-8 hover:fill-current hover:text-emerald-400 hover:scale-125 transition duration-200 ease-in-out'
          />
        </a>
      </div>
    </>
  )
}

export default Socials
