import Link from 'components/icons/Link'

function FooterLink({ link, label, route }) {
  return (
    <>
      <a
        href={link}
        className={
          'flex items-center gap-2 hover:text-emerald-400 transition duration-200 ease-in-out ' +
          (route === link ? 'text-emerald-400 italic' : '')
        }
      >
        <Link classes='w-4 h-4' />
        {label}
      </a>
    </>
  )
}

export default FooterLink
