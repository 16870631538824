function UndrawProgramming({ classes }) {
  return (
    <>
      <svg
        className={classes}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 397.72474 407.127'
        title='Undraw Programming'
        alt='Undraw Programming'
      >
        <g>
          <polygon
            points='172.27946 400.51778 162.93495 400.51687 158.4897 364.47332 172.28134 364.47429 172.27946 400.51778'
            fill='#ffb6b6'
          />
          <path
            d='M140.26397,400.3058h0c-.29096,.49002-.44448,2.0716-.44448,2.64146h0c0,1.75159,1.41992,3.17157,3.17157,3.17157h28.93888c1.19492,0,2.16366-.96868,2.16366-2.16366v-1.20482s1.43158-3.62111-1.51579-8.08432c0,0-3.66318,3.49476-9.13692-1.97897l-1.61416-2.92417-11.68427,8.54529-6.47641,.79718c-1.41691,.17439-2.67313-.02707-3.40197,1.20044h-.00011Z'
            fill='#2f2e41'
          />
        </g>
        <g>
          <polygon
            points='130.41116 400.51778 121.06664 400.51687 116.6214 364.47332 130.41303 364.47429 130.41116 400.51778'
            fill='#ffb6b6'
          />
          <path
            d='M98.39566,400.3058h0c-.29096,.49002-.44448,2.0716-.44448,2.64146h0c0,1.75159,1.41992,3.17157,3.17157,3.17157h28.93888c1.19492,0,2.16366-.96868,2.16366-2.16366v-1.20482s1.43158-3.62111-1.51579-8.08432c0,0-3.66318,3.49476-9.13692-1.97897l-1.61416-2.92417-11.68427,8.54529-6.47641,.79718c-1.41691,.17439-2.67313-.02707-3.40197,1.20044h-.00011Z'
            fill='#2f2e41'
          />
        </g>
        <polygon
          points='173.08183 174.39122 181.78174 196.68474 174.98494 374.76097 155.95389 374.76097 138.82595 214.08455 134.74787 375.30472 116.26056 374.76097 96.14202 195.86912 109.46376 179.82867 173.08183 174.39122'
          fill='#2f2e41'
        />
        <path
          d='M132.02914,47.97069l22.83726-1.63123,7.06867,11.41863,21.20602,5.98119-7.88429,55.19004,8.42804,82.37725s-30.44968-4.34995-32.62465,4.34995-55.46191-8.69991-55.46191-8.69991l18.21543-56.27753-1.63123-44.04328-4.8937-25.55598,22.29351-10.33114,2.44685-12.77799Z'
          fill='#e6e6e6'
        />
        <path
          d='M121.698,64.28302s-18.19831-.27187-20.65372,7.34055-10.61158,60.35561-10.61158,60.35561l-6.25306,23.10913,28.00283-13.86548-5.98119-9.22867,19.57479-37.53334-4.07808-30.1778Z'
          fill='#e6e6e6'
        />
        <path
          d='M132.83109,148.83523c3.00898,.00312,5.80637-1.73113,6.97623-4.50338,.88472-2.09655,.8013-4.10808,.1393-5.83266,.21303-.08828,.42424-.18014,.64234-.2582-.32043-.12983-.63182-.27652-.94302-.42347-1.08394-2.16037-3.16204-3.72815-5.63716-4.10895-3.06588-3.28413-4.95041-7.68465-4.97007-12.52775-.04052-9.98147,8.05643-18.31897,18.03512-18.55841,.07857-.00189,.15702-.00328,.23537-.00418,4.46835-.05146,8.52179,2.44805,10.78569,6.30078,1.321,2.2481,3.76211,3.75724,6.55892,3.75724,2.14111,0,4.07268-.88519,5.45336-2.30907,3.00816-3.10226,6.77491-5.41059,11.03773-6.11873,2.79944-.46504,5.69678-.6405,8.66117-.49313,20.29266,1.00876,37.75121,18.99389,38.18456,39.30699,.48266,22.62446-17.713,41.1163-40.22769,41.1163-11.71516,0-22.25584-5.00994-29.60706-13.00176-3.26368-3.54807-7.83707-5.53552-12.65765-5.4863-.04905,.0005-.09816,.00075-.14733,.00075-7.80783,0-14.13735-6.32952-14.13735-14.13735,0-.43302,.0215-.86074,.06187-1.28301,.07549-.78956,.76252-1.43655,1.55569-1.43573h0Z'
          fill='#6ee7b7'
        />
        <g>
          <path
            d='M395.81652,299.39217c-.98768-1.66544-5.26054,.11777-6.04609-1.39368-.78277-1.506,3.18724-3.80066,5.45536-9.04262,.40902-.94533,2.98659-6.90244,1.07842-8.74062-3.61682-3.48419-20.16758,10.89424-25.95964,6.18894-1.27113-1.0326-2.15915-3.09405-6.00631-6.44093-1.53047-1.33144-2.45593-1.93261-3.35157-1.70894-1.27084,.31733-1.37158,2.01824-2.73431,5.36252-2.04369,5.01544-3.25251,4.7126-4.44325,8.71409-.88394,2.97053-.68203,4.69751-1.72221,5.03399-1.47194,.47615-2.8268-2.67464-4.6922-2.39252-1.90357,.28792-3.05439,3.95645-3.41789,6.70315-.68214,5.15439,1.21075,8.5556,2.25989,12.13198,1.14014,3.88661,1.8168,9.79735-.8162,18.16481l-26.06075,73.42634c5.7534-15.06687,22.41225-56.36918,29.41232-71.7174,2.02076-4.4307,4.25202-8.9248,8.83345-10.69851,4.41082-1.7077,10.46849-.68815,18.19133-2.54863,.90196-.21725,3.40484-.85406,3.71989-2.32621,.26045-1.21708-1.21178-1.89641-.98558-3.03631,.30341-1.52901,3.19852-1.54776,6.7562-3.31179,2.50866-1.2439,4.07156-2.72322,5.41557-3.99536,.40521-.38353,6.42839-6.15529,5.11357-8.3723Z'
            fill='#6ee7b7'
          />
          <path
            d='M293.28575,338.04689c-1.10238,.00423-1.46003,2.61589-2.42852,2.56534-.96501-.05034-.94577-2.66093-2.86413-5.28661-.34596-.47351-2.52604-3.45741-3.9788-3.05124-2.75363,.76986-.47203,13.04172-4.45261,14.52687-.87357,.32594-2.14157,.16699-4.89427,1.08952-1.09507,.367-1.65715,.64777-1.8058,1.15189-.21093,.7153,.59482,1.25551,1.84302,2.88928,1.87194,2.45017,1.37452,2.95609,2.99491,4.69507,1.20291,1.29094,2.10878,1.69013,1.97381,2.29774-.191,.85982-2.12835,.61569-2.52812,1.61265-.40793,1.01736,1.06056,2.64068,2.30378,3.61159,2.33299,1.822,4.54849,1.87432,6.60651,2.39129,2.23654,.56182,5.33281,1.93515,8.67991,5.64173l28.51878,33.97664c-5.73488-7.17108-21.19972-27.2644-26.71299-35.12853-1.59156-2.2702-3.15351-4.66201-2.70217-7.42238,.43451-2.65759,2.68275-5.33656,3.9979-9.66379,.15362-.50537,.56323-1.91754-.06841-2.49693-.5222-.479-1.28041,.04757-1.77461-.39234-.6629-.59009,.16323-2.01643,.32393-4.27154,.11331-1.59017-.1618-2.78408-.39839-3.81079-.07132-.30954-1.16626-4.93109-2.63375-4.92545Z'
            fill='#6ee7b7'
          />
          <path
            d='M315.27831,342.98373l-.66418-5.92894,.30346-.44947c1.40422-2.07842,2.11993-4.10818,2.12852-6.03487,.00143-.30632-.01288-.61265-.0272-.9247-.05726-1.23675-.12883-2.77409,.67277-4.56623,.44947-.99913,1.71198-3.31231,3.61004-3.02602,.51102,.07157,.8975,.30632,1.19094,.58116,.04294-.06871,.08732-.13742,.13455-.21185,.59118-.90179,1.05925-1.28828,1.51015-1.66045,.3464-.28628,.70426-.58116,1.26538-1.27969,.2462-.30632,.43801-.57543,.59977-.8016,.49098-.68422,1.13225-1.50013,2.26308-1.48581,1.20812,.05726,1.84081,1.05639,2.26021,1.7177,.74863,1.17949,1.08788,2.02975,1.31261,2.59373,.08159,.20612,.17463,.43801,.22187,.50959,.38935,.57257,3.55422,.03722,4.74659-.15746,2.67819-.4466,4.99566-.83309,5.95042,.85599,.68422,1.20812,.17606,2.80558-1.55595,4.87542-.53965,.64414-1.11365,1.13369-1.62323,1.51731,.42227,.25193,.80016,.63841,.95333,1.24534h0c.36072,1.43715-.8746,2.87716-3.67016,4.28567-.69424,.35213-1.62896,.82164-2.93871,1.0478-.61694,.10593-1.17233,.13169-1.64757,.146-.01002,.27483-.07443,.57257-.24191,.87889-.49098,.90179-1.51301,1.33122-3.05322,1.22816-1.6948-.08875-3.09044-.41225-4.32146-.69567-1.075-.2462-2.00113-.45519-2.72829-.40652-1.3484,.10879-2.38761,1.18808-3.60718,2.58801l-3.04463,3.55851Z'
            fill='#6ee7b7'
          />
          <path
            d='M318.35931,307.6827l-5.56657,2.14634-.51174-.17953c-2.36663-.83111-4.51154-1.00856-6.3774-.52817-.29667,.07631-.58934,.16786-.88756,.26086-1.18178,.36909-2.65069,.82826-4.58755,.50746-1.08046-.18134-3.63823-.81582-3.84275-2.72443-.06039-.51246,.06865-.94585,.26007-1.2994-.07735-.02411-.15507-.04961-.23905-.07642-1.02225-.3431-1.51483-.69784-1.98919-1.03959-.36479-.26246-.74079-.53382-1.5588-.8994-.35876-.16045-.66771-.27773-.92751-.37683-.78638-.30137-1.73826-.71472-2.01125-1.81219-.25106-1.18313,.55492-2.04856,1.08823-2.62199,.95103-1.02333,1.68743-1.56715,2.17596-1.92759,.17869-.13121,.37939-.28002,.43664-.34387,.45509-.52185-.86553-3.44742-1.35628-4.55142-1.11132-2.47732-2.07299-4.62097-.68133-5.97293,.99506-.96828,2.66917-.88194,5.11064,.26842,.75995,.35861,1.37909,.78967,1.87942,1.18528,.13658-.47236,.41457-.93593,.9628-1.23803h0c1.29865-.71345,3.0049,.1162,5.07645,2.46307,.51671,.58222,1.20795,1.36728,1.75894,2.57682,.25895,.5699,.42475,1.10059,.55914,1.55665,.26839-.06002,.57272-.07323,.91151,.01107,.99684,.24618,1.67146,1.12587,1.96244,2.64184,.34404,1.66189,.38512,3.09393,.42322,4.35659,.03452,1.10229,.06728,2.05114,.2988,2.74218,.44725,1.27671,1.75484,2.00817,3.41833,2.83276l4.21441,2.04244Z'
            fill='#6ee7b7'
          />
          <path
            d='M315.27831,290.21584l-.66418-5.92894,.30346-.44947c1.40422-2.07842,2.11993-4.10818,2.12852-6.03487,.00143-.30632-.01288-.61265-.0272-.9247-.05726-1.23675-.12883-2.77409,.67277-4.56623,.44947-.99913,1.71198-3.31231,3.61004-3.02602,.51102,.07157,.8975,.30632,1.19094,.58116,.04294-.06871,.08732-.13742,.13455-.21185,.59118-.90179,1.05925-1.28828,1.51015-1.66045,.3464-.28628,.70426-.58116,1.26538-1.27969,.2462-.30632,.43801-.57543,.59977-.8016,.49098-.68422,1.13225-1.50013,2.26308-1.48581,1.20812,.05726,1.84081,1.05639,2.26021,1.7177,.74863,1.17949,1.08788,2.02975,1.31261,2.59373,.08159,.20612,.17463,.43801,.22187,.50959,.38935,.57257,3.55422,.03722,4.74659-.15746,2.67819-.4466,4.99566-.83309,5.95042,.85599,.68422,1.20812,.17606,2.80558-1.55595,4.87542-.53965,.64414-1.11365,1.13369-1.62323,1.51731,.42227,.25193,.80016,.63841,.95333,1.24534h0c.36072,1.43715-.8746,2.87716-3.67016,4.28567-.69424,.35213-1.62896,.82164-2.93871,1.0478-.61694,.10593-1.17233,.13169-1.64757,.146-.01002,.27483-.07443,.57257-.24191,.87889-.49098,.90179-1.51301,1.33122-3.05322,1.22816-1.6948-.08875-3.09044-.41225-4.32146-.69567-1.075-.2462-2.00113-.45519-2.72829-.40652-1.3484,.10879-2.38761,1.18808-3.60718,2.58801l-3.04463,3.55851Z'
            fill='#6ee7b7'
          />
          <path
            d='M321.34467,405.30205l-1.24247-.77869-.3006-1.43428,.3006,1.43428-1.4486,.16604c-.0229-.13455-.10163-.44374-.22044-.92756-.64843-2.65099-2.62523-10.71848-4.27422-23.42377-1.15086-8.86908-1.80216-17.98723-1.93671-27.10538-.13455-9.13246,.29774-16.04622,.64414-21.603,.26195-4.1912,.57973-8.182,.89177-12.06974,.83022-10.38639,1.61321-20.19734,1.03062-31.05896-.12883-2.42483-.39937-7.47202-3.34809-12.8427-1.71055-3.11477-4.06667-5.88028-7.00394-8.21635l1.82649-2.296c3.24217,2.58228,5.84878,5.64552,7.74828,9.10383,3.26936,5.95471,3.56424,11.45423,3.70595,14.09663,.59261,11.05343-.19897,20.95886-1.03778,31.44545-.30919,3.87342-.62696,7.84705-.88748,12.02107-.34354,5.50524-.77154,12.35888-.63841,21.37397,.13169,9.00936,.77583,18.01586,1.91095,26.77329,1.62896,12.54497,3.57712,20.49222,4.21696,23.10313,.34068,1.3942,.41225,1.68621,.06298,2.23874Z'
            fill='#6ee7b7'
          />
          <path
            d='M299.06032,272.09979c-.12024,0-.24191-.00286-.36501-.01145-2.4964-.13455-4.80814-1.62896-6.87225-4.44027-.96764-1.32263-1.46291-2.83135-2.45059-5.8402-.15316-.46378-.8975-2.81704-1.34983-6.05491-.2963-2.11564-.25909-3.00026,.16032-3.78181,.46521-.8703,1.21814-1.47723,2.06554-1.88661-.03722-.28056-.00716-.56971,.11451-.86458,.49957-1.22243,1.82792-1.06211,2.5465-.98482,.36358,.04581,.81734,.10593,1.30689,.08016,.7701-.03722,1.18378-.26624,1.81075-.60979,.59977-.32923,1.34554-.73861,2.45202-.96478,2.18148-.45519,4.00941,.16318,4.61204,.36644,3.1706,1.05639,4.73371,3.52129,6.54302,6.37555,.36072,.57257,1.5989,2.65672,2.41624,5.51383,.58975,2.06125,.50672,2.97735,.32923,3.65871-.36072,1.39993-1.21241,2.20152-3.37529,3.97076-2.25878,1.85226-3.39247,2.77982-4.36583,3.3438-2.26594,1.30832-3.68877,2.12995-5.57825,2.12995Z'
            fill='#6ee7b7'
          />
        </g>
        <polygon
          points='199.7253 136.87287 64.33299 111.31689 63.78924 121.10429 198.09407 153.1852 199.7253 136.87287'
          fill='#6ee7b7'
        />
        <path
          d='M65.96422,112.94813l-1.63123-1.63123s-7.06867-3.26247-7.06867-3.80621c0-.34034-10.43798-3.87597-18.24744-6.47363-4.36143-1.45074-8.96741,1.42007-9.58844,5.9743l-.97863,7.17659c-.58516,4.29116,2.6526,8.15359,6.98002,8.32669l19.11576,.76463,9.24365-2.17498,2.17498-8.15616Z'
          fill='#6ee7b7'
        />
        <polygon
          points='199.45343 139.86346 199.77628 138.50888 64.29741 112.60828 64.06112 114.30749 199.45343 139.86346'
          fill='#3f3d56'
        />
        <polygon
          points='199.14306 142.43563 199.45343 140.95095 64.15006 115.28931 64.06112 117.02621 199.14306 142.43563'
          fill='#3f3d56'
        />
        <polygon
          points='198.83269 145.51809 198.90968 143.12593 64.04386 117.24126 64.06112 118.65744 198.83269 145.51809'
          fill='#3f3d56'
        />
        <polygon
          points='198.47134 149.09809 198.36594 146.93214 63.84712 119.19269 63.73136 119.72369 198.47134 149.09809'
          fill='#3f3d56'
        />
        <polygon
          points='198.46895 151.26882 198.36594 149.65086 64.04943 120.09008 63.9289 120.62002 198.46895 151.26882'
          fill='#3f3d56'
        />
        <path
          d='M120.67046,60.81624l6.73686-3.73035,2.92209,59.66844-1.01899,7.34055s-17.00806-46.89874-8.63997-63.27864Z'
          fill='#3f3d56'
        />
        <circle cx='143.71964' cy='25.4053' r='16.85607' fill='#ffb6b6' />
        <path
          d='M129.31042,35.46457l4.02975,3.21695-4.57349-17.3543s12.77799-.27187,13.86548-7.34055c0,0,10.05927-.27187,12.77799-.27187s3.94541,17.98941,3.94541,17.98941c0,0,5.29824-14.72694-.1392-22.33936s-3.53434-2.99059-4.62183-5.70931-12.23425-5.21909-19.30292-2.44685c-7.06867,2.77224-7.34055,6.72402-9.51552,6.76041s-9.7874,10.91128-2.71872,19.61118,6.25306,7.88429,6.25306,7.88429Z'
          fill='#2f2e41'
        />
        <path
          d='M180.80675,146.07775c1.99792-1.59415,3.47014-3.50738,4.29983-5.39844l19.99208-13.98418-6.93252-9.31837-19.53356,15.18884c-2.02809,.38914-4.22041,1.39987-6.21833,2.99402-4.56425,3.64183-6.38554,8.94866-4.06801,11.85316,2.31753,2.90451,7.89626,2.30679,12.4605-1.33503h0Z'
          fill='#ffb6b6'
        />
        <path
          d='M104.21398,124.80635c-1.48247,2.08213-2.37176,4.32647-2.64939,6.37279l-15.37536,18.94303,9.22788,7.05253,14.60323-19.97515c1.84287-.93189,3.67269-2.50656,5.15516-4.58869,3.3867-4.75663,3.67832-10.35971,.65138-12.51486-3.02694-2.15516-8.22618-.04627-11.61288,4.71034h0Z'
          fill='#ffb6b6'
        />
        <path
          d='M174.44119,67.27361s4.8937-8.15616,9.7874-3.26247c3.87238,3.87238,25.10866,28.51334,33.80402,38.62982,2.39788,2.78978,3.71433,6.34437,3.71433,10.02305h0c0,5.69026-3.14162,10.91556-8.16725,13.58423l-27.17612,14.43083-7.61242-11.41863,23.381-16.85607-35.88712-19.57479,8.15616-25.55598Z'
          fill='#e6e6e6'
        />
        <polyline
          points='88.5082 142.43563 84.17965 155.0883 96.68577 161.61323 106.20129 155.0883 112.18248 141.22282 94.51079 136.05725'
          fill='#e6e6e6'
        />
        <path
          d='M290.69063,21.16735l-41.07854,11.1587,5.97689,26.65502c-1.93262-1.29685-4.25723-2.05486-6.75952-2.05486-6.70938,0-12.14836,5.43903-12.14836,12.14836s5.43898,12.14832,12.14836,12.14832,12.14832-5.43898,12.14832-12.14832c0-2.332-.66864-4.50276-1.80777-6.35279l-4.73867-21.13299,35.43803-11.14159,3.24107,15.74198c-1.94524-1.32331-4.29356-2.09818-6.82361-2.09818-6.70934,0-12.14836,5.43903-12.14836,12.14836s5.43903,12.14832,12.14836,12.14832,12.14832-5.43898,12.14832-12.14832c0-2.35686-.68219-4.54947-1.84382-6.41211l-5.9007-28.6599Z'
          fill='#6ee7b7'
        />
        <path
          d='M330.9778,46.08421c-6.6397,0-12.0222,5.3825-12.0222,12.0222,0,1.87694,.44247,3.64632,1.20951,5.22914l6.86155,28.30398,3.89489-.94413-5.29316-21.83427c1.61272,.80385,3.42497,1.26748,5.34942,1.26748,6.6397,0,12.0222-5.38256,12.0222-12.0222s-5.3825-12.0222-12.0222-12.0222Z'
          fill='#6ee7b7'
        />
        <path
          d='M379.90261,108.46605l-6.81163-27.24315-4.13536,.97192,5.328,21.30922c-1.63867-.74197-3.45977-1.16832-5.38773-1.16832-7.04964,0-12.76446,5.54095-12.76446,12.37612s5.71482,12.37612,12.76446,12.37612,12.76446-5.54101,12.76446-12.37612c0-2.28112-.64755-4.41084-1.75774-6.24578Z'
          fill='#6ee7b7'
        />
        <path
          d='M397.72474,406.28697h0c0,.46394-.37609,.84003-.84003,.84003H0v-1.68005H396.88472c.46393,0,.84003,.37609,.84003,.84003Z'
          fill='#e6e6e6'
        />
      </svg>
    </>
  )
}

export default UndrawProgramming
