import { Routes, Route, useLocation } from 'react-router-dom'

import Navigation from 'components/Navigation'
import Footer from 'components/Footer'

import Home from 'pages/Home'
import Projects from 'pages/Projects'
import Skills from 'pages/Skills'
import NotFound from 'pages/NotFound'

import AOS from 'aos'
import 'aos/dist/aos.css'

const App = () => {
  AOS.init()

  let location = useLocation()
  let route = location.pathname

  return (
    <>
      <div className='bg-gray-940 text-gray-50'>
        <Navigation route={route} />
        <Routes>
          <Route
            exact
            path='/'
            element={
              <>
                <Home />
              </>
            }
          />
          <Route
            path='/projects'
            element={
              <>
                <Projects />
              </>
            }
          />
          <Route
            path='/skills'
            element={
              <>
                <Skills />
              </>
            }
          />
          <Route
            path='*'
            element={
              <>
                <NotFound />
              </>
            }
          />
        </Routes>
        <Footer route={route} />
      </div>
    </>
  )
}

export default App
