import HtmlLogo from '../assets/images/skills/html.png'
import CssLogo from '../assets/images/skills/css.png'
import JavaScriptLogo from '../assets/images/skills/javascript.png'
import PhpLogo from '../assets/images/skills/php.png'
import LaravelLogo from '../assets/images/skills/laravel.png'
import ReactLogo from '../assets/images/skills/react.png'
import VueLogo from '../assets/images/skills/vue.png'
import TailwindLogo from '../assets/images/skills/tailwind.png'
import NodeLogo from '../assets/images/skills/node.png'
import MysqlLogo from '../assets/images/skills/mysql.png'
import LinuxLogo from '../assets/images/skills/linux.png'
import GitLogo from '../assets/images/skills/git.png'

const skills = [
    {
        name: 'HTML',
        logo: HtmlLogo
    },
    {
        name: 'CSS',
        logo: CssLogo
    },
    {
        name: 'JavaScript',
        logo: JavaScriptLogo
    },
    {
        name: 'PHP',
        logo: PhpLogo
    },
    {
        name: 'Laravel',
        logo: LaravelLogo
    },
    {
        name: 'React',
        logo: ReactLogo
    },
    {
        name: 'Vue',
        logo: VueLogo
    },
    {
        name: 'Tailwind',
        logo: TailwindLogo
    },
    {
        name: 'Node.js',
        logo: NodeLogo
    },
    {
        name: 'MySQL',
        logo: MysqlLogo
    },
    {
        name: 'Linux',
        logo: LinuxLogo
    },
    {
        name: 'Git',
        logo: GitLogo
    }
]

export default skills