import { useState } from 'react'
import NavigationIcon from 'components/NavigationIcon'
import NavigationMenu from 'components/NavigationMenu'

function Navigation({ route }) {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)

    // Prevent scrolling when menu is open (NOTE: Doesn't work in mobile safari)
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden'
  }

  return (
    <>
      <NavigationIcon isMenuOpen={isMenuOpen} onClick={toggleMenu} />
      <NavigationMenu isMenuOpen={isMenuOpen} route={route} />
    </>
  )
}

export default Navigation
